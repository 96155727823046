import React, { useEffect, useState } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { Form, Button, Card, notification } from 'antd'
import GetAllFormFields from '../../../components/_utils/formUtils'
import { addUserAction, userListAction, editUserAction } from '../actions'
import { useDispatch } from 'react-redux'
import { goBack } from 'connected-react-router'

const AddUsers = props => {
  const [loader, setLoader] = useState(false)
  const [id, setId] = useState(null)
  const dispatch = useDispatch()

  const [form] = Form.useForm()
  let { resetFields, setFieldsValue, getFieldValue } = form

  const onFinish = async values => {


    let x = null
    setLoader(true)
    if (id) {
      values._id = id
      x = await editUserAction(values)
      setLoader(false)

    } else {
      x = await addUserAction(values)
      setLoader(false)

    }

    if (!x.error) {
      notification.success({
        message: x.message
      })
      if (!id) {
        // setStaffShow(false)
        resetFields()
      } else {
        dispatch(goBack())
      }

    } else {
      notification.error({
        message: x.message ? x.message : 'Error Saving'
      })
    }


  }

  const onFinishFailed = errorInfo => {
    return notification.error({
      message: 'Please Fill All Required Fields'
    })
  }
  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search)
    let id = searchParams.get('id')
    setId(id)


  }, [])

  useEffect(async () => {
    if (id) {
      let { data } = await userListAction({ _id: id })
      if (data && data.length) {
        let agent = data[0]
        setFieldsValue({
          name: agent.name,
          email: agent.email,
          mobile: agent.mobile,
          userType: agent.userType
        })

      }

    }
  }, [id])


  const subInputTypes1 =
    {
      fields: [
        {
          label: 'Name',
          key: 'name',
          required: true
        },
        {
          label: 'Email',
          key: 'email',
          required: true,
          type: 'email'
        },
        {
          label: 'Password',
          key: 'password',
          required: true,
          type:'password',
          hidden: id ? true : false
        },
        {
          label: 'Mobile',
          key: 'mobile',
          required: true
        },
        {
          label: 'User Type',
          key: 'userType',
          required: true,
          keyAccessor: (x) => x.value,
          valueAccessor: (x) => `${x.id}`,
          type: 'select', options: [{ id: 'Admin', value: 'admin' }, { id: 'Dealer', value: 'dealer' }]
        }


      ]
    }


  return (
    <PageHeaderWrapper title={id ? 'Edit User' : 'Add Users'}>
      <Card>
        <div>

          <Form
            form={form}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >

            <GetAllFormFields inputSchema={subInputTypes1}/>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit" loading={loader}>
                {id ? 'Edit' : 'Add'}
              </Button>
            </Form.Item>


          </Form>


        </div>
      </Card>
    </PageHeaderWrapper>
  )
}

export default AddUsers
