import React, { useEffect, useState } from 'react'
import { AutoComplete, Spin } from 'antd'
import 'react-quill/dist/quill.snow.css'
import { ListAction } from '../containers/make/actions'
import { ListAction as ModelList } from '../containers/model/actions'
import _ from 'lodash'

export const SearchMake = props => {
  const [options, setOptions] = useState([])
  const [searching, setSearching] = useState(false)

  const handleSearch = async value => {
    setSearching(true)

    let { data } = await ListAction({
      make: value,
      results: 20,
      regExFilters: ['make']
    })
    if (data) {
      setOptions(
        data.map((v, idx) => {
          return {
            key: v._id,
            value: v.make,
            info: v,
            label: <div>{v.make}</div>
          }
        })
      )
    } else {
      setOptions([])
    }

    setSearching(false)
  }

  useEffect(() => {
    handleSearch()
  }, [])
  return (
    <div>
      <AutoComplete
        optionLabelProp="name"
        dropdownMatchSelectWidth={'100%'}
        dropdownClassName="certain-category-search-dropdown"
        allowClear={true}
        options={options}
        defaultOpen={true}
        style={{ width: 150 }}
        onClear={e => {
          props.onSelect(null)
        }}
        onSelect={(e, option) => {
          props.onSelect({
            _id: option.key,
            make: option.value,
            info: option.info
          })
        }}
        onSearch={handleSearch}
        placeholder="Search Make"
      />
      {searching && <Spin size="small" style={{ marginLeft: 10 }}/>}
    </div>
  )
}
export const SearchModel = props => {
  const [options, setOptions] = useState([])
  const [searching, setSearching] = useState(false)

  const handleSearch = async value => {
    setSearching(true)

    let { data } = await ModelList({
      model: value,
      makeId: props && props.makeId,
      results: 20,
      regExFilters: ['model']
    })
    if (data) {
      setOptions(
        data.map((v, idx) => {
          return {
            key: v._id,
            value: v.model,
            info: v,
            label: <div>{v.model}</div>
          }
        })
      )
    } else {
      setOptions([])
    }

    setSearching(false)
  }
  useEffect(() => {
    if (props.makeId) handleSearch()
    if (!props.makeId) setOptions([])
  }, [props.makeId])
  return (
    <div>
      <AutoComplete
        optionLabelProp="name"
        dropdownMatchSelectWidth={'100%'}
        dropdownClassName="certain-category-search-dropdown"
        allowClear={true}
        options={options}
        defaultOpen={true}
        style={{ width: 150 }}
        onClear={e => {
          props.onSelect(null)
        }}
        onSelect={(e, option) => {
          props.onSelect({
            _id: option.key,
            model: option.value,
            info: option.info
          })
        }}
        onSearch={handleSearch}
        placeholder="Search Model"
      />
      {searching && <Spin size="small" style={{ marginLeft: 10 }}/>}
    </div>
  )
}
