import React, { useEffect, useState } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { Form, Button, Card, notification, Spin } from 'antd'
import GetAllFormFields from '../../../components/_utils/formUtils'
import { addModelAction, ListAction, updateModelAction } from '../actions'
import { allMakes } from '../../make/actions'
import { useDispatch } from 'react-redux'
import { goBack } from 'connected-react-router'
import _ from 'lodash'

const AddModel = props => {
  const [loader, setLoader] = useState(false)
  const [id, setId] = useState(null)
  const [makes, setMakes] = useState([])
  const [spinLoader, setSpinLoader] = useState(true)

  const dispatch = useDispatch()

  const [form] = Form.useForm()
  let { resetFields, setFieldsValue, getFieldValue } = form

  const onFinish = async values => {


    let x = null
    setLoader(true)
    if (id) {
      values._id = id
      x = await updateModelAction(values)
      setLoader(false)

    } else {
      x = await addModelAction(values)
      setLoader(false)

    }

    if (!x.error) {
      notification.success({
        message: x.message
      })
      if (!id) {
        resetFields()
      } else {
        dispatch(goBack())
      }

    } else {
      notification.error({
        message: x.message ? x.message : 'Error Saving'
      })
    }


  }

  const onFinishFailed = errorInfo => {
    return notification.error({
      message: 'Please Fill All Required Fields'
    })
  }

  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search)
    let id = searchParams.get('id')
    setId(id)
    makesList()

  }, [])

  useEffect(async () => {

    if (id) {
      setSpinLoader(true)
      let { data } = await ListAction({ _id: id })
      if (data && data.length) {
        let model = data[0]
        setFieldsValue({ makeId: model && model.makeId && model.makeId._id, model: model.model })

        setSpinLoader(false)
      }

    } else {
      setSpinLoader(false)

    }
  }, [id])


  const makesList = async () => {
    let { data } = await allMakes()
    if (data) {
      setMakes(data)
    }
  }
  const subInputTypes1 =
    {
      fields: [
        {
          label: 'Make',
          key: 'makeId',
          type: 'select',
          options: makes,
          showSearch: true,
          keyAccessor: (x) => x._id,
          valueAccessor: (x) => x.make,
          required: true
        },
        {
          label: 'Model',
          key: 'model',
          required: true
        }


      ]
    }


  return (
    <PageHeaderWrapper title={id ? 'Edit Model' : 'Add Model'}>
      <Spin spinning={spinLoader}>
        <Card>
          <div>

            <Form
              form={form}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >

              <GetAllFormFields inputSchema={subInputTypes1}/>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" loading={loader}>
                  {id ? 'Edit' : 'Add'}
                </Button>
              </Form.Item>


            </Form>


          </div>
        </Card>
      </Spin>
    </PageHeaderWrapper>
  )
}

export default AddModel
