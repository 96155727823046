import React, { useEffect, useState, useRef, useCallback } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { Form, Button, Card, notification, Select, Spin, Upload, Tooltip } from 'antd'
import GetAllFormFields from '../../../components/_utils/formUtils'
import { addCarAction, ListAction, updateCarAction, deleteCarImageAction } from '../actions'
import { allMakes } from '../../make/actions'
import { allModels } from '../../model/actions'
import { useDispatch } from 'react-redux'
import { handlePreview, generateArrayOfYears, API_URL } from '../../../request'
import { goBack } from 'connected-react-router'
import _ from 'lodash'
import ImgCrop from 'antd-img-crop'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'

import { UploadOutlined } from '@ant-design/icons'

const type = 'DragableUploadList'

const DragableUploadListItem = ({ originNode, moveRow, file, fileList }) => {
  const ref = useRef(null)
  const index = fileList.indexOf(file)
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {}
      if (dragIndex === index) {
        return {}
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
      }
    },
    drop: (item) => {
      moveRow(item.index, index)
    }
  })
  const [, drag] = useDrag({
    type,
    item: {
      index
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })
  drop(drag(ref))
  const errorNode = <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>
  return (
    <div
      ref={ref}
      className={`ant-upload-draggable-list-item ${isOver ? dropClassName : ''}`}
      style={{
        cursor: 'move'
      }}
    >
      {file.status === 'error' ? errorNode : originNode}
    </div>
  )
}

const AddModel = props => {
  const [loader, setLoader] = useState(false)
  const [id, setId] = useState(null)
  const [makes, setMakes] = useState([])
  const [models, setModels] = useState([])
  const [yearsList, setYears] = useState([])
  const [spinLoader, setSpinLoader] = useState(true)
  const [fileList, setFileList] = useState([])


  const dispatch = useDispatch()
  const [items, setItems] = useState([])

  const [form] = Form.useForm()
  let { resetFields, setFieldsValue, getFieldValue } = form

  const onFinish = async values => {

    let x = null
    if (!fileList.length) return notification.error({ message: 'Upload atleast 1 file.' })
    let newFiles = _.cloneDeep(fileList)

    values.photos = handleChange(newFiles)
    setLoader(true)
    if (id) {
      values._id = id
      x = await updateCarAction(values)
      setLoader(false)

    } else {
      x = await addCarAction(values)
      setLoader(false)

    }

    if (!x.error) {
      notification.success({
        message: x.message
      })
      if (!id) {
        resetFields()
        setFileList([])
        setItems([])
      } else {
        dispatch(goBack())
      }

    } else {
      notification.error({
        message: x.message ? x.message : 'Error Saving'
      })
    }


  }

  const onFinishFailed = errorInfo => {
    return notification.error({
      message: 'Please Fill All Required Fields'
    })
  }

  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search)
    let id = searchParams.get('id')
    setId(id)

  }, [])
  useEffect(async () => {

    if (id) {
      setSpinLoader(true)
      let { data } = await ListAction({ _id: id })
      if (data && data.length) {
        let car = data[0]
        modelList(car && car.makeId && car.makeId._id)

        setFieldsValue({
          ...car,
          modelId: car && car.modelId && car.modelId._id,
          makeId: car && car.makeId && car.makeId._id,
          price: car && car.newPrice,
          odometer: car && car.odometer ? parseInt(car.odometer.replace(/,/g, '')) : ''
        })
        let newCars = _.cloneDeep(car && car.photos)
        let imageUrls = _.each(newCars, (item) => {
          item.newUrl = item.url
          item.url = `${API_URL}${item.url}`
        })
        setFileList(imageUrls)
        setSpinLoader(false)
      }

    } else {
      setSpinLoader(false)

    }
  }, [id])

  useEffect(() => {
    makesList()
    setYears(generateArrayOfYears())
  }, [])
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = fileList[dragIndex]
      setFileList(
        update(fileList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        })
      )
    },
    [fileList]
  )

  const makesList = async () => {
    let { data } = await allMakes()
    if (data) {
      setMakes(data)
    }
  }
  const modelList = async (makeId) => {
    let { data } = await allModels({ makeId: makeId })
    if (data) {
      setModels(data)
    }
  }

  const subInputTypes1 =
    {
      fields: [
        {
          label: 'Make',
          key: 'makeId',
          type: 'select',
          options: makes,
          showSearch: true,
          keyAccessor: (x) => x._id,
          valueAccessor: (x) => x.make,
          required: true,
          onChange: (val) => {
            modelList(val)
            setFieldsValue({ modelId: '' })
          }
        },
        {
          label: 'Model',
          key: 'modelId',
          type: 'select',
          showSearch: true,
          options: models,
          disabled: !(models && models.length),
          keyAccessor: (x) => x._id,
          valueAccessor: (x) => x.model,
          required: true

        },
        {
          label: 'Car Name',
          key: 'carName',
          required: true
        },
        // {
        //   label: 'Photos',
        //   key: 'photos',
        //   type: 'file',
        //   limit: 30,
        //   required: true,
        //   customProps: {
        //     onPreview: (file) => {
        //       handlePreview(file)
        //     },
        //     onChange: (vl) => {
        //       handleFileChange(vl)
        //     }
        //   }
        //
        // },

        {
          label: 'Color',
          key: 'color'
        },
        {
          label: 'Odometer',
          key: 'odometer',
          required: true,
          //type: 'number',
          customProps: {
            style: { width: 150 },
            addonAfter: 'Km'

          }

        },
        {
          label: 'Cylinders',
          key: 'cylinders',
          // required: true,
          type: 'number'
        },
        {
          label: 'Wheel Size',
          //   key: 'wheelSize',
          required: true
        },
        {
          label: 'Price',
          key: 'price',
          type: 'number',
          required: true
        },
        {
          label: 'Warranty',
          key: 'warranty',
          required: true
        },
        {
          label: 'Manufactured Year',
          key: 'manufacturedYear',
          type: 'select',
          required: true,
          options: yearsList

        },
        {
          label: 'Description',
          key: 'description',
          type: 'textArea',
          rows: 5
          //  required: true
        }


      ]
    }


  const handleChange = (info) => {
    let array = []
    _.each(info, (item) => {
      if (item && item.response && item.response.files[0].path) {
        item.response.path = item.response.files[0].path.replace(/^public/, '')
        array.push(item)
      } else {
        delete item.url
        item.url = item.newUrl
        array.push(item)

      }

    })
    return array
    //setItems(array)
  }

  const uploadProps = {
    action: `${API_URL}/filesuploader`,
    onPreview(inf) {
      handlePreview(inf)
    }

  }

  return (
    <PageHeaderWrapper title={id ? 'Edit Car' : 'Add Car'}>
      <Spin spinning={spinLoader}>
        <Card>
          <div>

            <Form
              form={form}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >

              <GetAllFormFields inputSchema={subInputTypes1}/>


              <Form.Item label={'Features'} name={'features'}>
                <Select
                  mode="tags"
                  placeholder="Tags Mode"
                  onChange={ss => {
                  }}
                />
              </Form.Item>
              <Form.Item label={'Photo'} name={'photo'} required={true}>

                <DndProvider backend={HTML5Backend}>
                  <ImgCrop
                    aspect={3 / 2}
                    rotate>
                    <Upload
                      {...uploadProps}
                      fileList={fileList}
                      onChange={v => {
                        if (v.fileList) {
                          setFileList(v.fileList)
                        }
                      }}
                      onRemove={async (v) => {
                        if (v) {
                          let abc = await deleteCarImageAction(v)
                        }
                      }}
                      listType="picture-card"
                      itemRender={(originNode, file, currFileList) => (
                        <DragableUploadListItem
                          originNode={originNode}
                          file={file}
                          fileList={currFileList}
                          moveRow={moveRow}
                        />
                      )}
                    >
                      Upload
                    </Upload>
                  </ImgCrop>
                </DndProvider>


              </Form.Item>


              <div style={{ textAlign: 'center' }}>
                <Button type="primary" htmlType="submit" loading={loader}>
                  {id ? 'Save' : 'Add'}
                </Button>
              </div>


            </Form>

          </div>
        </Card>
      </Spin>
    </PageHeaderWrapper>
  )
}

export default AddModel
