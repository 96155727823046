import React, { useEffect, useState } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { Form, Button, Card, notification, Spin } from 'antd'
import GetAllFormFields from '../../../components/_utils/formUtils'
import { addBlogAction, ListAction, updateBlogAction } from '../actions'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { goBack } from 'connected-react-router'
import { handlePreview } from '../../../request'

const AddMake = props => {
  const [loader, setLoader] = useState(false)
  const [id, setId] = useState(null)
  const dispatch = useDispatch()
  const [spinLoader, setSpinLoader] = useState(true)

  const [form] = Form.useForm()
  let { resetFields, setFieldsValue, getFieldValue } = form

  const onFinish = async values => {


    let x = null
    setLoader(true)
    if (id) {
      values._id = id
      x = await updateBlogAction(values)
      setLoader(false)

    } else {
      x = await addBlogAction(values)
      setLoader(false)

    }

    if (!x.error) {
      notification.success({
        message: x.message
      })
      if (!id) {
        resetFields()
      } else {
        dispatch(goBack())
      }

    } else {
      notification.error({
        message: x.message ? x.message : 'Error Saving'
      })
    }


  }

  const onFinishFailed = errorInfo => {
    return notification.error({
      message: 'Please Fill All Required Fields'
    })
  }

  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search)
    let id = searchParams.get('id')
    setId(id)


  }, [])
  useEffect(async () => {

    if (id) {
      setSpinLoader(true)
      let { data } = await ListAction({ _id: id })
      if (data && data.length) {
        let blog = data[0]
        setFieldsValue({
          file: blog && blog.file,
          date: blog && moment(blog.date),
          subject: blog && blog.subject,
          body: blog && blog.body
        })
        setSpinLoader(false)
      }

    } else {
      setSpinLoader(false)
      setFieldsValue({ date: moment() })

    }
  }, [id])


  const subInputTypes1 =
    {
      fields: [
        {
          label: 'Subject',
          key: 'subject',
          required: true
        },
        {
          label: 'Body',
          key: 'body',
          type: 'textArea',
          required: true,
          rows: 8

        },
        {
          label: 'File',
          key: 'file',
          type: 'file',
          limit: 1,
          required: true,
          customProps: {
            onPreview: (file) => {
              handlePreview(file)
            }
          }
        },
        {
          label: 'Date',
          key: 'date',
          type: 'date',
          required: true

        }


      ]
    }


  return (
    <PageHeaderWrapper title={id ? 'Edit Blog' : 'Add Blog'}>
      <Spin spinning={spinLoader}>
        <Card>
          <div>

            <Form
              form={form}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >

              <GetAllFormFields inputSchema={subInputTypes1}/>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" loading={loader}>
                  {id ? 'Edit' : 'Add'}
                </Button>
              </Form.Item>


            </Form>


          </div>
        </Card>
      </Spin>
    </PageHeaderWrapper>
  )
}

export default AddMake
