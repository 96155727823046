import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils4'
import moment from 'moment'
import {
  Modal,
  Card,
  Button, Tooltip,
  Select

} from 'antd'
import { ListAction } from '../actions'

import { connect } from 'react-redux'
import { EyeOutlined } from '@ant-design/icons'

const { Option } = Select

class Communication extends Component {

  constructor(props) {
    super(props)
    this.table = React.createRef()
    this.state = { visible: false, key: Date.now(), currentPage: 1 }

  }


  apiRequest = (params) => {
    if (params.page) {
      this.state.currentPage = params.page
    }

    return new Promise(async (resolve) => {

      let data = await ListAction({
        ...params, regExFilters: ['to', 'from', 'subject']
      })

      console.log(data)
      this.setState({ count: data.total })
      resolve(data)
    })


  }
  showModal = () => {
    this.setState({
      visible: true
    })
  }

  async componentDidMount() {

  }

  render() {
    const { submitting, dispatch } = this.props
    const { visible, singleMail, currentPage } = this.state


    const columns = [
      {
        title: 'S.No',
        dataIndex: 'sno',
        width: 50,
        key: 'sno',
        render: (value, item, index) => (currentPage - 1) * 10 + index + 1
      },
      {
        title: 'From',
        key: 'from',
        width: 150,
        dataIndex: 'from',
        searchTextName: 'from'


      },
      {
        title: 'To',
        dataIndex: 'to',
        width: 100,
        key: 'to',
        searchTextName: 'to'

      },


      {
        title: 'Subject',
        dataIndex: 'subject',
        width: 180,
        searchTextName: 'subject'

      },
      {
        title: 'Email',
        dataIndex: 'html',
        key: 'html',
        width: 80,
        render: (item, record) => (
          <React.Fragment>
            <Tooltip title={'View'}>
              <Button icon={<EyeOutlined/>}
                      onClick={() => {
                        this.setState({ singleMail: record }, () => {
                          this.showModal()
                        })

                      }}>View Mail</Button>
            </Tooltip>

          </React.Fragment>
        )
      },


      {
        title: 'Date',
        width: 120,
        key: 'date',
        dataIndex: 'date',
        sorter: true,
        render: (text, record) => {
          return (
            <div>
              {text ? moment(text).format('lll') : ''}
            </div>
          )
        }
      }

    ]


    return (
      <PageHeaderWrapper
        title={this.state.count ? `All Mails: ${this.state.count}` : `All Mails: 0`}>

        <Card bordered={true}>

          <TableComp ref={this.table}
                     columns={columns}
                     apiRequest={this.apiRequest}
                     pagination={{
                       ...this.state.pagination,
                       defaultPageSize: 10,
                       pageSizeOptions: ['10', '25', '50', '100'],
                       showSizeChanger: true,
                       ...this.props.pagination
                     }}/>
        </Card>


        <Modal
          visible={visible}
          title="Email"
          onCancel={() => this.setState({ visible: false })}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
          footer={false}
        >

          <div style={{
            margin: '5px',
            width: '100%',
            padding: '3px'
          }} dangerouslySetInnerHTML={{ __html: singleMail && singleMail.html }}>
          </div>
        </Modal>

      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Communication)
