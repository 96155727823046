import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils4'
import { Button, Card, Tag, Drawer, notification, Row, Col, Switch, Tooltip, Popconfirm } from 'antd'
import moment from 'moment'
import { connect } from 'react-redux'
import { ListAction, DeleteModel } from '../actions'
import { getUrlPushWrapper } from '../../../routes'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { SearchMake } from '../../../components/searchMakeFilters'

class AllModels extends Component {
  state = {
    data: [],
    pagination: {},
    filters: { makeId: null }


  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
  }


  apiRequest = async params => {
    return new Promise(async resolve => {
      if (!params.sortField) {
        params.sortField = 'createdAt'
        params.sortOrder = 'descend'
      }

      let data = await ListAction({
        ...params, ...this.state.filters,
        regExFilters: ['model']
      })
      if (!data) return resolve([])
      this.setState({ count: data.total })
      return resolve(data)
    })
  }
  deleteModel = async (data) => {
    let x = await DeleteModel(data)

    this.table.current.reload()
    if (!x.error) {
      notification.success({
        message: x.message,
        duration: 20,
        key: `${data._id}-close`
      })
    } else {
      notification.error({
        message: x.message || 'Try Again',
        duration: 20,
        key: `${data._id}-close`
      })
    }
  }


  render() {
    const { dispatch } = this.props
    let { filters } = this.state

    const columns = [
      {
        title: 'Make',
        dataIndex: 'makeId',
        key: 'makeId',
        width: 100,
        render: (val) => {
          return val && val.make

        }
      },
      {
        title: 'Model',
        dataIndex: 'model',
        key: 'model',
        width: 50,
        searchTextName: 'model'

      },

      {
        key: 'actions',
        title: 'Actions',
        width: 50,
        render: (text, record) => {
          return (
            <React.Fragment>
              <Tooltip title="Edit Model">
                <Button
                  shape="circle"
                  style={{ marginRight: 6 }}
                  size="small"
                  onClick={() => {
                    dispatch(
                      getUrlPushWrapper('model.editModel', { id: record._id })
                    )
                  }}
                  icon={<EditOutlined/>}
                />
              </Tooltip>
              <Tooltip title="Delete Model">
                <Popconfirm
                  title="Are you sure delete this Model?"
                  onConfirm={() => {
                    this.deleteModel({ _id: record._id })
                  }}
                  onCancel={() => {
                  }}
                  okText="Yes"
                  cancelText="No">
                  <Button
                    shape="circle"
                    size="small"
                    style={{ marginRight: 6 }}
                    icon={<DeleteOutlined/>}
                  />
                </Popconfirm>
              </Tooltip>
            </React.Fragment>
          )
        }
      }


    ]
    return (
      <PageHeaderWrapper title={`All Models (${this.state.count || 0})`}>

        <Card bordered={true}>
          <Row>
            <Col>
              <SearchMake onSelect={e => {
                this.setState({
                  filters: {
                    ...filters,
                    makeId: e && e._id
                  }
                }, () => {
                  this.table.current.reload()
                })
              }}/>

            </Col>
          </Row>

          <TableComp
            key={this.state.key}

            ref={this.table}
            columns={columns}
            apiRequest={this.apiRequest}
            pagination={{
              ...this.state.pagination,
              defaultPageSize: 10,
              pageSizeOptions: ['10', '25', '50', '100'],
              showSizeChanger: true,
              ...this.props.pagination
            }}
          />
        </Card>

      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllModels)
