import React, { Fragment } from 'react'
import { Layout, Icon } from 'antd'

const {Footer} = Layout
const FooterView = () => (
  <Footer style={{padding: 0}}>
    {/* <p style={{textAlign: 'center'}}> Build details #{BUILD_NUMBER} (${BUILD_TIMESTAMP})</p> */}
  </Footer>
)
export default FooterView
