import { addCarUrl, deleteCarImageUrl } from '../api/index'
import axios from 'axios'
import { notification } from 'antd'
import { API_URL, errorObj, getToken } from '../../../request'

export const addCarAction = async params => {
  try {
    let { data } = await axios.post(
      addCarUrl(),
      { ...params },
      getToken()
    )


    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
export const ListAction = data => {
  return new Promise(async next => {
    await axios
      .get(addCarUrl(), { params: { ...data }, ...getToken() })
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

export const DeleteCar = data => {
  return new Promise(async next => {
    await axios
      .delete(`${addCarUrl()}/${data._id}`, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const updateCarAction = async params => {
  try {
    let { data } = await axios.put(
      addCarUrl(),
      { ...params },
      getToken()
    )

    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
export const deleteCarImageAction = async params => {
  try {
    let { data } = await axios.post(
      deleteCarImageUrl(),
      { ...params },
      getToken()
    )


    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
