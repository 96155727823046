import Dashboard from './containers/dashboard'
import { push } from 'connected-react-router'
import _ from 'lodash'
import {
  DashboardOutlined,
  UserOutlined,
  CarOutlined,
  InboxOutlined,
  InsertRowBelowOutlined,
  FileTextOutlined
} from '@ant-design/icons'
import AddUsers from './containers/users/views/add'
import AllUsers from './containers/users/views/list'
import AddMake from './containers/make/views/add'
import AddModel from './containers/model/views/add'
import ModelList from './containers/model/views/list'
import CarList from './containers/car/views/list'
import AddCar from './containers/car/views/add'
import MakeList from './containers/make/views/list'
import AddBlog from './containers/blog/views/add'
import BlogList from './containers/blog/views/list'
import CommunicationList from './containers/communication/views/list'
import { Path } from 'path-parser'

let menu = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    title: 'Dashboard',
    icon: <DashboardOutlined/>,
    key: 'dashboard',
    homepage: true,
    component: Dashboard,
    authority: ['admin', 'dealer']
  },

  {
    path: '/users',
    icon: <UserOutlined/>,
    name: 'Users',
    key: 'users',
    authority: ['admin'],
    children: [
      {
        path: '/users/add',
        name: 'Add User',
        title: 'Add User',
        key: 'add',
        component: AddUsers
      },
      {
        path: '/users/all',
        name: 'All User',
        title: 'All User',
        component: AllUsers,
        isLazy: true
      },
      {
        path: '/users/edit/:id',
        name: 'Edit User',
        title: 'Edit User',
        dontShowOnMenu: true,
        key: 'edit',
        component: AddUsers,
        isLazy: true
      }
    ]
  },
  {
    path: '/make',
    icon: <InsertRowBelowOutlined/>,
    name: 'Make',
    key: 'make',
    authority: ['admin'],
    children: [
      {
        path: '/make/add',
        name: 'Add Make',
        title: 'Add Make',
        key: 'addMake',
        component: AddMake
      },
      {
        path: '/make/edit',
        name: 'Add Make',
        title: 'Add Make',
        key: 'editMake',
        dontShowOnMenu: true,
        component: AddMake
      },
      {
        path: '/make/list',
        name: 'Make List',
        title: 'Make List',
        key: 'makeList',
        component: MakeList
      }
    ]
  },
  {
    path: '/model',
    icon: <InboxOutlined/>,
    name: 'Model',
    key: 'model',
    authority: ['admin'],
    children: [
      {
        path: '/model/add',
        name: 'Add Model',
        title: 'Add Model',
        key: 'addModel',
        component: AddModel
      },
      {
        path: '/model/edit',
        name: 'Add Model',
        title: 'Add Model',
        key: 'editModel',
        dontShowOnMenu: true,
        component: AddModel
      },
      {
        path: '/model/list',
        name: 'Model List',
        title: 'Model List',
        key: 'modelList',
        component: ModelList
      }
    ]
  },
  {
    path: '/car',
    icon: <CarOutlined/>,
    name: 'Car',
    key: 'car',
    authority: ['admin', 'dealer'],
    children: [
      {
        path: '/car/add',
        name: 'Add Car',
        title: 'Add Car',
        key: 'addCar',
        component: AddCar
      },
      {
        path: '/car/edit',
        name: 'Add Car',
        title: 'Add Car',
        key: 'editCar',
        dontShowOnMenu: true,
        component: AddCar
      },
      {
        path: '/car/list',
        name: 'Car List',
        title: 'Car List',
        key: 'carList',
        component: CarList
      }
    ]
  },
  {
    path: '/blog',
    icon: <FileTextOutlined/>,
    name: 'Blog',
    key: 'blog',
    authority: ['admin'],
    children: [
      {
        path: '/blog/add',
        name: 'Add Blog',
        title: 'Add Blog',
        key: 'addBlog',
        component: AddBlog
      },
      {
        path: '/blog/edit',
        name: 'Add Blog',
        title: 'Add Blog',
        key: 'editBlog',
        dontShowOnMenu: true,
        component: AddBlog
      },
      {
        path: '/blog/list',
        name: 'Blog List',
        title: 'Blog List',
        key: 'blogList',
        component: BlogList
      }
    ]
  },
  {
    path: '/communication',
    icon: <FileTextOutlined/>,
    name: 'Communication',
    key: 'communication',
    component: CommunicationList,
    authority: ['admin']
  }
]

export const getUrlPushWrapper = (keyString, query) => {
  return push(getUrlPath(keyString, query))
}

export const getUrlPath = (keyString, params) => {
  if (!params) params = {}

  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  let queryString = Object.keys(params)
    .map(key => key + '=' + params[key])
    .join('&')

  return `${val.path}?${queryString}`
}

export const getUrlParams = (keyString, route) => {
  let obj = getUrlObject(keyString)

  if (obj) {
    console.log('objjjjj is', obj, route)
    const path = new Path(obj.path)

    return path.test(route)
  }

  return { error: true }
}

export const getUrlObject = keyString => {
  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  return val
}


export default menu
