import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils4'
import { Button, Card, notification, Tooltip, Popconfirm, Row, Col, Tag, Switch } from 'antd'
import { connect } from 'react-redux'
import { ListAction, DeleteCar, updateCarAction } from '../actions'
import { generateArrayOfYears, timeStampFormat } from '../../../request'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { getUrlPushWrapper } from '../../../routes'
import { SearchMake, SearchModel } from '../../../components/searchMakeFilters'
import moment from 'moment'

class AllCars extends Component {
  state = {
    data: [],
    pagination: {},
    filters: { makeId: null, modelId: null }

  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
  }

  updateCar = async (values) => {
    let x = await updateCarAction(values)
    this.table.current.reload()
    if (!x.error) {
      notification.success({
        message: x.message,
        duration: 20,
        key: `${values._id}-close`
      })
    } else {
      notification.error({
        message: x.message || 'Try Again',
        duration: 20,
        key: `${values._id}-close`
      })
    }
  }

  apiRequest = async params => {
    return new Promise(async resolve => {
      let user = { ...JSON.parse(localStorage.getItem('user')) }
      if (!params.sortField) {
        params.sortField = 'createdAt'
        params.sortOrder = 'descend'
      }

      if (user && user.userType == 'dealer') {
        params.carAddedById = user && user._id
      }
      let data = await ListAction({
        ...params, ...this.state.filters,
        regExFilters: ['carName', 'carAddedByName']
      })
      if (!data) return resolve([])
      this.setState({ count: data.total })
      return resolve(data)
    })
  }
  deleteCar = async (data) => {
    let x = await DeleteCar(data)

    this.table.current.reload()
    if (!x.error) {
      notification.success({
        message: x.message,
        duration: 20,
        key: `${data._id}-close`
      })
    } else {
      notification.error({
        message: x.message || 'Try Again',
        duration: 20,
        key: `${data._id}-close`
      })
    }
  }


  render() {
    let user = { ...JSON.parse(localStorage.getItem('user')) }

    const { dispatch } = this.props
    let { filters } = this.state

    const columns = [
      {
        title: 'Make',
        dataIndex: 'makeId',
        key: 'makeId',
        width: 100,
        fixed: 'left',
        render: (val) => {
          return val && val.make

        }
      },
      {
        title: 'Model',
        dataIndex: 'modelId',
        key: 'modelId',
        width: 100,
        render: (val) => {
          return val && val.model

        }


      },
      {
        title: 'Car Name',
        dataIndex: 'carName',
        key: 'carName',
        width: 150,
        searchTextName: 'carname'


      },
      {
        title: 'Slug Name',
        dataIndex: 'slugName',
        key: 'slugName',
        width: 150,
        searchTextName: 'slugName'


      },
      {
        title: 'Color',
        dataIndex: 'color',
        key: 'color',
        width: 80,
        searchTextName: 'color'


      },
      {
        title: 'Manufactured Year',
        width: 150,
        dataIndex: 'manufacturedYear',
        key: 'manufacturedYear',
        filters: _.map(generateArrayOfYears(), (item) => {
          return { value: item, text: item }
        })
      },
      {
        title: 'Odometer',
        width: 100,
        dataIndex: 'odometer',
        key: 'odometer',
        render: (val) => {
          return `${val} Km`
        }
      },
      {
        title: 'Cylinders',
        width: 80,
        dataIndex: 'cylinders',
        key: 'cylinders'
      },
      {
        title: 'Price',
        width: 100,
        dataIndex: 'price',
        key: 'price'
      },
      {
        title: 'Added By',
        key: 'carAddedByName',
        width: 150,
        searchTextName: 'carAddedByName',
        render: (text, record) => {
          return <div>
            {record && record.carAddedByName}
          </div>

        }

      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 150,
        sorter: true,
        render: (text, record) => {
          return <Tag>
            {text ? moment(text).format(timeStampFormat) : ''}
          </Tag>

        }

      },
      {
        title: 'Sold',
        dataIndex: 'isSold',
        key: 'isSold',
        render: (val, record, key) => {
          return (
            <Tag>{val ? 'Yes' : 'No'}</Tag>
          )
        },
        width: 80
      },


      {
        key: 'actions',
        fixed: 'right',
        title: 'Actions',
        width: 100,
        render: (text, record) => {
          return (
            <React.Fragment>
              <Tooltip title="Edit Car">
                <Button
                  shape="circle"
                  style={{ marginRight: 6 }}
                  size="small"
                  onClick={() => {
                    dispatch(
                      getUrlPushWrapper('car.editCar', { id: record._id })
                    )
                  }}
                  icon={<EditOutlined/>}
                />
              </Tooltip>
              {user && user.userType != 'dealer' && <Tooltip title="Delete Car">
                <Popconfirm
                  title="Are you sure delete this Car?"
                  onConfirm={() => {
                    this.deleteCar({ _id: record._id })
                  }}
                  onCancel={() => {
                  }}
                  okText="Yes"
                  cancelText="No">
                  <Button
                    shape="circle"
                    size="small"
                    style={{ marginRight: 6 }}
                    icon={<DeleteOutlined/>}
                  />
                </Popconfirm>
              </Tooltip>}
              {user && user.userType == 'dealer' && <Tooltip title="Sold Car">
                <Switch
                  checked={record.isSold}
                  onChange={(vall) => {
                    this.updateCar({ _id: record._id, isSold: vall })
                  }}
                />
              </Tooltip>}
            </React.Fragment>
          )
        }
      }


    ]
    return (
      <PageHeaderWrapper title={`All Cars (${this.state.count || 0})`}>

        <Card bordered={true}>
          <Row style={{ marginBottom: '15px' }}>
            <Col span={12}>
              <div>
                <b>Select Make</b>
              </div>
              <SearchMake onSelect={e => {
                this.setState({
                  filters: {
                    ...filters,
                    makeId: e && e._id
                  }
                }, () => {
                  this.table.current.reload()
                })
              }}/>
            </Col>
            <Col span={12}>
              <div>
                <b>Select Model</b>
              </div>
              <SearchModel makeId={filters.makeId} onSelect={e => {
                this.setState({
                  filters: {
                    ...filters,
                    modelId: e && e._id
                  }
                }, () => {
                  this.table.current.reload()
                })
              }}/>

            </Col>
          </Row>


          <TableComp
            key={this.state.key}

            ref={this.table}
            columns={columns}
            apiRequest={this.apiRequest}
            extraProps={{ scroll: { x: 1200 } }}

            pagination={{
              ...this.state.pagination,
              defaultPageSize: 10,
              pageSizeOptions: ['10', '25', '50', '100'],
              showSizeChanger: true,
              ...this.props.pagination
            }}
          />
        </Card>

      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllCars)
