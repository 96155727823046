import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils4'
import { Card, Button, Tooltip, Tag, Switch, Row, Col, Checkbox, notification, Select, Drawer, Popconfirm } from 'antd'
import _ from 'lodash'
import { connect } from 'react-redux'
import {
  EditOutlined

} from '@ant-design/icons'
import { userListAction } from '../actions'
import { getUrlPushWrapper } from '../../../routes'

class AllUsers extends Component {

  state = {
    data: [],
    pagination: {},
    loading: false,
    searchText: '',
    dataSearchParams: {},
    campArr: []
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
  }

  apiRequest = async params => {
    return new Promise(async resolve => {
      // if (!params.sortField) {
      //   params.sortField = '_id'
      //   params.sortOrder = 'descend'
      // }
      let data = await userListAction({ ...params })
      if (!data) return resolve([])
      this.setState({ count: data.total })
      return resolve(data)
    })
  }

  componentDidMount() {
  }

  render() {
    const { categories, dispatch } = this.props
    const { avgData, selectedRowKeys } = this.state

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: true,
        width: 150,
        key: 'name',
        searchTextName: 'name',
        fixed: 'left'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        width: 150,
        sorter: true,
        key: 'email',
        searchTextName: 'email'

      },
      {
        title: 'Mobile',
        dataIndex: 'mobile',
        width: 150,
        key: 'mobile',
        searchTextName: 'mobile'

      },
      {
        title: 'User Type',
        dataIndex: 'userType',
        width: 150,
        sorter: true,
        key: 'userType',
        render: (val, record) => {
          return _.startCase(val && val.replace(/([A-Z])/g, ' $1'))
        },
        filters: [{ value: 'admin', text: 'Admin' }, { value: 'dealer', text: 'Dealer' }]
      },
      {
        key: 'actions',
        title: 'Actions',
        width: 100,
        fixed: 'right',
        render: (text, record) => {
          return (
            <React.Fragment>
              <Tooltip title="Edit User">
                <Button
                  shape="circle"
                  style={{ marginRight: 6 }}
                  size="small"
                  onClick={() => {
                    dispatch(
                      getUrlPushWrapper('users.edit', { id: record._id })
                    )
                  }}
                  icon={<EditOutlined/>}
                />
              </Tooltip>


              {/*<Tooltip title="Change Password">*/}
              {/*  <Button*/}
              {/*    shape="circle"*/}
              {/*    style={{ marginRight: 6 }}*/}
              {/*    size="small"*/}
              {/*    onClick={() => {*/}
              {/*      dispatch(*/}
              {/*        getUrlPushWrapper('users.changePass', { id: record._id })*/}
              {/*      )*/}
              {/*    }}*/}
              {/*    icon={<SettingOutlined/>}*/}
              {/*  />*/}
              {/*</Tooltip>*/}


            </React.Fragment>
          )
        }
      }
    ]
    return (
      <PageHeaderWrapper title={`All Users (${this.state.count || 0})`}>
        <Card bordered={true}>

          <TableComp
            ref={this.table}
            columns={columns}
            apiRequest={this.apiRequest}
            pagination={{
              ...this.state.pagination,
              defaultPageSize: 10,
              pageSizeOptions: ['10', '25', '50', '100'],
              showSizeChanger: true,
              ...this.props.pagination
            }}
            //    extraProps={{scroll: {x: 1700}}}
          />
        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllUsers)
