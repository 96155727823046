import React, { Component, useEffect, useState } from 'react'


const Dashboard = (props) => {

  return (
    <div className={'asdf'} style={{ padding: 20 }}>
      <h2>Dashboard</h2>
    </div>
  )

}

export default Dashboard

