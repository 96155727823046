import { communicationList} from '../api/index'
import axios from 'axios'
import { notification } from 'antd'
import { API_URL, getToken, errorObj } from '../../../request'


export const ListAction = data => {
  return new Promise(async next => {
    await axios
      .get(communicationList(), { params: { ...data }, ...getToken() })
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
