import { addModelUrl, allModelsUrl } from '../api/index'
import axios from 'axios'
import { notification } from 'antd'
import { API_URL, errorObj, getToken } from '../../../request'
import { addMakeUrl } from '../../make/api'

export const addModelAction = async params => {
  try {
    let { data } = await axios.post(
      addModelUrl(),
      { ...params },
      getToken()
    )


    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}

export const allModels = data => {
  return new Promise(async next => {
    await axios
      .get(allModelsUrl(), { params: { ...data }, ...getToken() })
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const ListAction = data => {
  return new Promise(async next => {
    await axios
      .get(addModelUrl(), { params: { ...data }, ...getToken() })
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}
export const updateModelAction = async params => {
  try {
    let { data } = await axios.put(
      addModelUrl(),
      { ...params },
      getToken()
    )

    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
export const DeleteModel = data => {
  return new Promise(async next => {
    await axios
      .delete(`${addModelUrl()}/${data._id}`, getToken())
      .then(x => {
        return next(x.data)
      })
      .catch(error => {
        return next({ ...errorObj, message: error })
      })
  })
}

