import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils4'
import { Button, Card, Tooltip, Popconfirm, notification, Tag } from 'antd'
import { connect } from 'react-redux'
import { ListAction, DeleteBlog } from '../actions'
import _ from 'lodash'
import moment from 'moment'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { getUrlPushWrapper } from '../../../routes'
import { timeStampFormat } from '../../../request'

class AllBlog extends Component {
  state = {
    data: [],
    pagination: {},
    users: []

  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
  }


  apiRequest = async params => {
    return new Promise(async resolve => {
      if (!params.sortField) {
        params.sortField = 'createdAt'
        params.sortOrder = 'descend'
      }

      let data = await ListAction({
        ...params,
        regExFilters: ['details']
      })
      if (!data) return resolve([])
      this.setState({ count: data.total })
      return resolve(data)
    })
  }
  deleteBlog = async (data) => {
    this.setState({ loading: true })
    let x = await DeleteBlog(data)
    this.setState({ loading: false })

    this.table.current.reload()
    if (!x.error) {
      notification.success({
        message: x.message,
        duration: 20,
        key: `${data._id}-close`
      })
    } else {
      notification.error({
        message: x.message || 'Try Again',
        duration: 20,
        key: `${data._id}-close`
      })
    }
  }


  render() {
    const { dispatch } = this.props
    let {} = this.state

    const columns = [

      {
        title: 'Subject',
        dataIndex: 'subject',
        key: 'subject',
        width: 100,
        searchTextName: 'subject'

      },
      {
        title: 'Body',
        dataIndex: 'body',
        key: 'body',
        width: 100,
        searchTextName: 'body'

      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        width: 150,
        sorter: true,
        render: (text, record) => {
          return <Tag>
            {text ? moment(text).format(timeStampFormat) : ''}
          </Tag>

        }

      },

      {
        key: 'actions',
        title: 'Actions',
        width: 20,
        render: (text, record) => {
          return (
            <React.Fragment>
              <Tooltip title="Edit Blog">
                <Button
                  shape="circle"
                  style={{ marginRight: 6 }}
                  size="small"
                  onClick={() => {
                    dispatch(
                      getUrlPushWrapper('blog.editBlog', { id: record._id })
                    )
                  }}
                  icon={<EditOutlined/>}
                />
              </Tooltip>
              <Tooltip title="Delete Blog">
                <Popconfirm
                  title="Are you sure delete this Blog?"
                  onConfirm={() => {
                    this.deleteBlog({ _id: record._id })
                  }}
                  onCancel={() => {
                  }}
                  okText="Yes"
                  cancelText="No">
                  <Button
                    shape="circle"
                    size="small"
                    style={{ marginRight: 6 }}
                    icon={<DeleteOutlined/>}
                  />
                </Popconfirm>
              </Tooltip>

            </React.Fragment>
          )
        }
      }


    ]
    return (
      <PageHeaderWrapper title={`All Blogs (${this.state.count || 0})`}>

        <Card bordered={true}>

          <TableComp
            key={this.state.key}

            ref={this.table}
            columns={columns}
            apiRequest={this.apiRequest}
            pagination={{
              ...this.state.pagination,
              defaultPageSize: 10,
              pageSizeOptions: ['10', '25', '50', '100'],
              showSizeChanger: true,
              ...this.props.pagination
            }}
          />
        </Card>

      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllBlog)
